export const ADDRESSES = {
  Masterchef: "0xbD17B1ce622d73bD438b9E658acA5996dc394b0d",
  Timelock_48: "0xc2d82a3e2bae0a50f4aeb438285804354b467bc0",
  Timelock_24: "0x0040E05CE9A5fc9C0aBF89889f7b60c2fC278416",
  Timelock_12: "0xD92c7fAa0Ca0e6AE4918f3a83d9832d9CAEAA0d3",
  ControllerV3: "0x2ff3e6C2E054ABf45E21f790163970df82b0ea90",
  ControllerV4: "0x6847259b2B3A4c17e7c43C54409810aF48bA5210",
  psCRV_v2: "0x68d14d66B2B0d6E157c06Dc8Fefa3D8ba0e66a89",
  StrategyCurveSCRVv3_2: "0x8E1ed86C27e1861d044c27b66574d6a0249A3c1C",
  psUNIv2_ETH_DAI_v2: "0xCffA068F1E44D98D3753966eBd58D4CFe3BB5162",
  StrategyUniEthDaiLpV4: "0x0697D05738b456BCC8F06023219dA351Ae252912",
  psUNIv2_ETH_USDC_v2: "0x53Bf2E62fA20e2b4522f05de3597890Ec1b352C6",
  StrategyUniEthUsdcLpV4: "0xb48B92F8962F880D2F072F4e5fdfC748ceDa7727",
  psUNIv2_ETH_USDT_v2: "0x09FC573c502037B149ba87782ACC81cF093EC6ef",
  StrategyUniEthUsdtLpV4: "0x3577797668c6Fe415B21bf85Ba44DF34318dD80D",
  p3CRV: "0x1BB74b5DdC1f4fC91D6f9E7906cf68bc93538e33",
  StrategyCurve3CRVv2: "0x8f01bb820BcD0b0B7d873862c531A88822747042",
  prenCRV: "0x2E35392F4c36EBa7eCAFE4de34199b2373Af22ec",
  StrategyCurveRenCRVv2: "0xB606602C2AC912B52437817add9362B87776a6A6",
  psUNIv2_ETH_WBTC: "0xc80090AA05374d336875907372EE4ee636CBC562",
  StrategyUniEthWBtcLpV2: "0xD8dE542D2140eeCc49FfDf056e51aa9261f974d6",
  pDAI: "0x6949Bb624E8e8A90F87cD2058139fcd77D2F3F87",
  StrategyCmpV2: "0xCd892a97951d46615484359355e3Ed88131f829D",
  SmartTreasury: "0xe5c700572799553f9d65cbe8dfeeb543be584431",
  SmartTreasuryCRP: "0xe2ab9b005abe28cca6a93b46e957fbfbe0f19488", // Controller
  BActions: "0x2fcc6f96418764439f8dc26af559ed5cddaeefac",
  GovernanceProxy: "0x23F0CCeeAbEab58c3B547973409F4C1bcC3f2a3D",
  TreasuryProxy: "0x5066e82C995BE4a03498D1E91F809Db907B1F54b",
};
